<template>
  <b-container fluid>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-left">
          <b-form-select text-field="placa.placa" value-field="id" style="width: 50%" v-model="placaSeleccionada">
            <template #first>
              <!-- <b-form-select-option  selected :value="null" disabled>-- selecciona una placa --</b-form-select-option> -->
              <b-form-select-option v-for="it of listaServicios" :key="it.servicioId" :value="it">{{
                it.servicioId +
                " | " +
                it.placa.placa +
                " | " +
                $moment.tz(it.fecha, "America/Bogota").format(" DD-MM-YYYY HH:mm") +
                " | " +
                it.conductor.nombres +
                " " +
                it.conductor.apellidos
              }}</b-form-select-option>
            </template>
          </b-form-select>

          <vs-button   primary @click="obtenerCoordenadasPlaca()">OBTENER DATOS</vs-button>
          <vs-button   primary @click="seleccionaPlaca()">INICIAR RUTA</vs-button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8">
        <l-map style="height: 400px" :zoom="zoom" :center="center">
          <l-icon-default />
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker :icon="customIcon" :lat-lng="markerLatLng" ref="markerRef"></l-marker>
          <l-polyline :lat-lngs="polylinePath" :options="polylineOptions"></l-polyline>
        </l-map>
      </div>
      <div class="col-md-4">
        <div class="mt-0" style="overflow-y: scroll; height: 400px">
          <table id="pendientesJefe" class="table table-bordered table-responsive-md table-striped text-center">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Latitud</th>
                <th>Longitud</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in coordinates" :key="item.id">
                <td>{{ $moment.tz(item.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                <td>{{ item.lat }}</td>
                <td>{{ item.lng }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIconDefault, LPolyline } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIconDefault,
    LPolyline,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      center: [4.7279077, -74.062912],
      markerLatLng: [4.7279077, -74.062912],
      customIcon: L.icon({
        iconUrl: require("@/assets/images/vehiculo1.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 16],
        popupAnchor: [10, -32],
      }),
      coordinates: [
        // [4.7279077, -74.062912],
        // [4.7286456, -74.0667592],
        // [4.7293151, -74.0673967],
        // [4.7293098, -74.0673919],
        // [4.7292511, -74.0676391],
        // [4.7296193, -74.0689143],
        // [4.7241644, -74.0730205],
        // [4.7208569, -74.074729],
        // [4.7160998, -74.0729646],
        // [4.7266615, -74.0706394],
        // [4.7293207, -74.0691661],
      ],
      polylinePath: [], // Para almacenar las coordenadas de la línea
      polylineOptions: {
        color: "red",
        weight: 6,
      },
      persona: {},
      empresaId: {},
      listaServicios: [],
      placaSeleccionada: {},
    };
  },
  mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
    this.getServicios();
    // this.moveMarker();
  },
  methods: {
    async obtenerCoordenadasPlaca() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/CoordenadasPorServicio/" + this.placaSeleccionada.servicioId,
        });
        console.log(res);
        if (res.length > 0) {
          this.coordinates = res;
        } else {
          this.coordinates = [];
        }
      } catch (error) {
        this.coordinates = [];
        console.log("err", error);
      }
    },
    async seleccionaPlaca() {
      console.log(this.placaSeleccionada);
      await this.obtenerCoordenadasPlaca();
      if (this.coordinates.length > 0) {
        this.center = [this.coordinates[0].lat, this.coordinates[0].lng];
        this.markerLatLng = [this.coordinates[0].lat, this.coordinates[0].lng];
        this.iniciar();
      }
    },
    async getServicios() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/ServiciosPorEmpresa/" + this.empresaId,
        });
        console.log(res);
        if (res.length > 0) {
          this.listaServicios = res;
        } else {
          this.listaServicios = [];
        }
      } catch (error) {
        this.listaServicios = [];
        console.log("err", error);
      }
    },
    iniciar() {
      this.zoom = 16;
      this.$nextTick(() => {
        this.addPopup();

        this.moveMarker();
      });
    },
    addPopup() {
      const marker = this.$refs.markerRef.mapObject;

      if (!marker) {
        console.error("No se pudo obtener la referencia del marcador.");
        return;
      }

      marker.bindPopup("123", { autoClose: false }).openPopup();
    },
    async moveMarker() {
      const marker = this.$refs.markerRef.mapObject;

      if (!marker) {
        console.error("No se pudo obtener la referencia del marcador.");
        return;
      }

      const duration = 2000; // Duración de la animación en milisegundos

      for (let i = 0; i < this.coordinates.length; i++) {
        const startLatLng = marker.getLatLng();
        const targetLatLng = L.latLng(this.coordinates[i]);

        const startTime = new Date().getTime();

        while (new Date().getTime() - startTime <= duration) {
          const progress = (new Date().getTime() - startTime) / duration;
          const lat = startLatLng.lat + progress * (targetLatLng.lat - startLatLng.lat);
          const lng = startLatLng.lng + progress * (targetLatLng.lng - startLatLng.lng);

          marker.setLatLng([lat, lng]);
          this.center = [lat, lng];

          marker.setPopupContent("lat:" + lat + " / lng:" + lng + " /" + this.$moment.tz(this.coordinates[i].fecha, "America/Bogota").format(" DD-MM-YYYY HH:mm:ss"));
          this.polylinePath.push([lat, lng]);

          await this.sleep(16); // Esperar 16 milisegundos (aproximadamente 60 FPS)
        }
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style></style>
